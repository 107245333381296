<template>
  <div class="csn-paginator">
    <div class="csn-paginator-rows">
      <span>{{ t('rows_per_page') }}</span>
      <span>{{ limit }}</span>
      <div @click="toggleRowSelect" class="csn-paginator-row-select-arrow">
        <DropdownArrow />
      </div>
      <div
        v-if="isRowSelectOpen"
        class="csn-paginator-row-select-list-container"
      >
        <ul class="csn-paginator-row-select-list">
          <li v-for="limit in limitList" :key="limit">
            <button @click="setLimit({ limit })">
              {{ limit }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="csn-paginator-page-select-container">
      <span>{{ firstRow }}</span>
      <span>-</span>
      <span>{{ lastRow }}</span>
      <span>{{ t('of') }}</span>
      <span>{{ total }}</span>
      <button
        @click="backMax"
        :disabled="isFirstPage"
        class="csn-paginator-select-btn csn-paginator-btn-far-back"
        :class="{ 'csn-paginator-select-btn-disabled': isFirstPage }"
      >
        <VerticalBar />
        <SlideArrow :isBack="true" />
      </button>
      <button
        @click="back"
        :disabled="isFirstPage"
        class="csn-paginator-select-btn"
        :class="{ 'csn-paginator-select-btn-disabled': isFirstPage }"
      >
        <SlideArrow :isBack="true" />
      </button>
      <button
        @click="next"
        :disabled="isLastPage"
        class="csn-paginator-select-btn"
        :class="{ 'csn-paginator-select-btn-disabled': isLastPage }"
      >
        <SlideArrow />
      </button>
      <button
        @click="nextMax"
        :disabled="isLastPage"
        class="csn-paginator-select-btn csn-paginator-btn-far-forward"
        :class="{ 'csn-paginator-select-btn-disabled': isLastPage }"
      >
        <SlideArrow />
        <VerticalBar />
      </button>
    </div>
  </div>
</template>
<script>
import { PAGINATOR, Digit, EMPTY_OBJECT } from '@/constants'
import { isNil } from '@/helpers'

const limitList = [Digit.FIVE, Digit.TEN, Digit.TWENTY]

export default {
  name: PAGINATOR,
  components: {
    DropdownArrow: () => import('@/components/svg/DropdownArrow'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
    VerticalBar: () => import('@/components/VerticalBar'),
  },
  props: {
    value: Object,
  },
  data: () => ({
    isRowSelectOpen: false,
  }),
  computed: {
    limitList: () => limitList,
    t() {
      return this.$createComponentTranslator(PAGINATOR)
    },
    firstRow() {
      return this.limit * (this.page - Digit.ONE) + Digit.ONE
    },
    lastRow() {
      const defaultLastRow = this.firstRow - Digit.ONE + this.limit

      return defaultLastRow > this.total ? this.total : defaultLastRow
    },
    isFirstPage() {
      return this.firstRow === Digit.ONE
    },
    isLastPage() {
      return this.lastRow === this.total
    },
    hasItems() {
      return isNil(this.total)
    },
    page() {
      return Number(this.value.page)
    },
    total() {
      return Number(this.value.total)
    },
    limit() {
      return Number(this.value.limit)
    },
  },
  methods: {
    toggleRowSelect() {
      this.isRowSelectOpen = !this.isRowSelectOpen
    },
    setLimit({ limit, page = Digit.ONE }) {
      this.$emit('input', {
        ...this.value,
        ...(limit ? { limit } : EMPTY_OBJECT),
        ...(page ? { page } : EMPTY_OBJECT),
      })
      this.$emit('getData', {
        ...(limit ? { limit } : EMPTY_OBJECT),
        ...(page ? { page } : EMPTY_OBJECT),
      })
      this.toggleRowSelect()
      window.scrollTo(Digit.NOUGHT, Digit.NOUGHT)
    },
    back() {
      this.setLimit({ page: this.page - Digit.ONE })
    },
    backMax() {
      this.setLimit({ page: Digit.ONE })
    },
    next() {
      this.setLimit({ page: this.page + Digit.ONE })
    },
    nextMax() {
      const fullPageList = ~~(this.total / this.limit)
      const page =
        this.total % this.limit > Digit.NOUGHT
          ? fullPageList + Digit.ONE
          : fullPageList

      this.setLimit({ page })
    },
  },
}
</script>
